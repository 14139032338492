import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/vercel/path0/apps/store/src/common/components/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu"] */ "/vercel/path0/apps/store/src/common/components/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/store/src/common/components/scroll-to-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollTo"] */ "/vercel/path0/apps/store/src/common/components/scroll-to.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/apps/store/src/common/layout/error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardSwiperRow"] */ "/vercel/path0/apps/store/src/features/contentful/components/card-row-component/card-swiper-row.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatButton"] */ "/vercel/path0/apps/store/src/features/contentful/components/customer-service-component/chat-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroCarousel"] */ "/vercel/path0/apps/store/src/features/contentful/components/hero-carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscriptionForm"] */ "/vercel/path0/apps/store/src/features/email-subscription/components/subscription-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@4.36.1_react-dom@18.2.0_react@18.2.0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.13.0_next@14.2.16_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.16_@babel+core@7.24.3_@opentelemetry+api@1.9.0_@playwright+test@1.45.2_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
